
import { Options, Vue } from 'vue-class-component';

import Link from "@/types/Link";

@Options({})
export default class Footer extends Vue {

  public async mounted(): Promise<void> {
    await this.store.dispatch('fetchLinks');
  }

  get links(): Array<Link> {
    return this.store.getters.links;
  }


}
